import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'hooks';
import { useLocation } from 'react-router-dom';

import { media } from 'components/Styles/Media';

import { colors } from 'components/Styles/Colors';

import { backgroundTheme } from 'utils/app';
import { APP_THEMES } from 'utils/constants';

const { SubMenu } = Menu;
const { Sider } = Layout;

const StyledSider = styled(Sider)`
  transform: ${({ collapsed }) => (collapsed ? 'translateX(-100%)' : 'translateX(0)')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  min-height: 100vh;

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-top: 0;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${({ theme }) => colors(theme).secondaryBG};
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid ${({ theme }) => colors(theme).borderNormal};
  }

  .ant-menu-submenu.ant-menu-submenu-open {
    span {
      color: ${({ theme }) => colors(theme).primaryColor};
    }
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 58px !important;
    a {
      color: ${({ theme }) => colors(theme).secondaryTextColor};
    }

    &.ant-menu-item-selected {
      a {
        color: ${({ theme }) => colors(theme).primaryColor};
      }
    }
  }

  .ant-menu-item-selected a {
    color: #f04287;
  }

  .ant-menu.ant-menu,
  .ant-layout-sider-trigger {
    background: ${({ theme }) => colors(theme).backgroundColor};
    color: ${({ theme }) => colors(theme).secondaryTextColor};
  }

  .ant-layout-sider-trigger {
    border-right: 1px solid ${({ theme }) => colors(theme).borderNormal};
  }

  .ant-layout-sider-children {
    height: auto;
    border-right: 1px solid ${({ theme }) => colors(theme).borderNormal};
  }

  .ant-menu-submenu-title:hover {
    color: ${({ theme }) => colors(theme).primaryColor};
  }

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    font-size: 20px;
    margin-right: 14px;
    color: ${({ theme }) => colors(theme).secondaryTextColor};
  }
  .ant-menu-submenu-arrow {
    color: ${({ theme }) => colors(theme).secondaryTextColor};
  }
  .ant-menu-submenu-title:hover .anticon {
    color: ${({ theme }) => colors(theme).primaryColor};
  }

  background: ${({ theme }) => colors(theme).backgroundColor};

  &.ant-layout-sider-collapsed {
    .logo {
      h1 {
        display: none;
      }
    }
  }

  ${media.md`
    transition: none;
    transform: none;

    position: relative;
    max-height: 100vh;

    .ant-layout-sider-trigger {
      transition: none;
    }
  `}
`;

const sideBarClassName = 'main-side-bar';

export const Sidebar = ({ items, children, collapsed, toggleSidebar = () => {} }) => {
  const { pathname } = useLocation();
  const openedKey = pathname.split('-')[0].replace('/', '');

  const { theme } = useSelector(({ app: { theme } }) => ({ theme }));

  const [sideBarOpenedKeys, setSideBarOpenedKeys] = useState([openedKey]);

  useEffect(() => {
    if (!collapsed) {
      setSideBarOpenedKeys([openedKey]);
    }
  }, [openedKey, collapsed]);

  // submenu keys of first level
  const rootSubmenuKeys = items.map((menuItem) => menuItem.key);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => sideBarOpenedKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setSideBarOpenedKeys(keys);
    } else {
      setSideBarOpenedKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const MenuItem = ({ onClickItem, path, title, ...others }) => {
    return (
      <Menu.Item onClick={onClickItem} {...others}>
        <Link to={path}>{title}</Link>
      </Menu.Item>
    );
  };

  const renderMenu = (menuItems) =>
    menuItems.map((menuItem) => {
      const { key, title, subMenu, ...others } = menuItem;
      return subMenu ? (
        <SubMenu className="item" key={key} title={title} {...others}>
          {renderMenu(subMenu)}
        </SubMenu>
      ) : (
        <MenuItem className="item" key={key} title={title} {...others} />
      );
    });

  return (
    <StyledSider
      width={208}
      className={sideBarClassName}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={toggleSidebar}
      theme={theme}
      style={{
        overflow: 'auto',
        height: '100vh',
		zIndex: 999
      }}
    >
      {children}
      <Menu
        mode="inline"
        onOpenChange={onOpenChange}
        openKeys={sideBarOpenedKeys}
        defaultSelectedKeys={[pathname]}
        theme={APP_THEMES.LIGHT}
      >
        {renderMenu(items)}
      </Menu>
    </StyledSider>
  );
};

Sidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.node,
      path: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          title: PropTypes.string,
          icon: PropTypes.node,
          path: PropTypes.string,
        }),
      ),
      hasDivider: PropTypes.bool,
    }),
  ).isRequired,
};
