import { Link } from 'react-router-dom';
import { useSelector } from 'hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Breadcrumb } from 'antd';
import { colors } from 'components/Styles/Colors';

const Main = styled.main`
  margin: 24px;
  padding:  ${({padding}) => padding};
  min-height: ${({minHeight}) => minHeight};
  min-width: ${({minWidth}) => minWidth};
  background-color: ${({ theme }) => colors(theme).backgroundColor};
`;

const Layout = ({ children, breadcrumbItems, minWidth, minHeight = '80vh', padding = '24px' }) => {
  const { theme } = useSelector(({ app: { theme } }) => ({ theme }));

  return (
    <>
      {breadcrumbItems.length > 0 && (
        <Breadcrumb>
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <Link to={item.link}>{item.title}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
      <Main className={theme} theme={theme} minHeight={minHeight} minWidth={minWidth} padding={padding}>
        {children}
      </Main>
    </>
  );
};

Layout.propTypes = {
  breadcrumbItems: PropTypes.array,
};

Layout.defaultProps = {
  breadcrumbItems: [],
};

export default Layout;
