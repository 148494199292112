import { FullLayout } from 'components/Layout';
import { ProtectedRoute } from 'components/Routing';
import AlbumDetail from 'containers/Albums/Detail';
import CreateAlbums from 'containers/Albums/Form';
import AlbumsPage from 'containers/Albums/List';
import CreateCategory from 'containers/Categories/Form';
import CategoriesList from 'containers/Categories/List';
import CreateCity from 'containers/City/Form';
import CitiesList from 'containers/City/List';
import CreateCountry from 'containers/Country/Form';
import CountriesList from 'containers/Country/List';
import EventsList from 'containers/Events/List';
import EventsPopup from 'containers/EventsPopup/List';
import EventsBanner from 'containers/EventsBanner/List';
import CreateFilter from 'containers/Filters/Form';
import FiltersPage from 'containers/Filters/List';
import FrameList from 'containers/Frames/List';
import CreateTag from 'containers/Hashtag/Form';
import HashtagList from 'containers/Hashtag/List';
import HomePage from 'containers/HomePage';
import LoginPage from 'containers/LoginPage';
import NotFoundPage from 'containers/NotFoundPage';
import CreatePostNotifications from 'containers/PostNotifications/Form';
import PostNotificationsPage from 'containers/PostNotifications/List';
import Privacy from 'containers/Privacy';
import PushNotificationsList from 'containers/PushNotifications/List';
import CreateReports from 'containers/Reports/Form';
import ReportsPage from 'containers/Reports/List';
import SongDetail from 'containers/Songs/Detail';
import CreateSong from 'containers/Songs/Form';
import SongsPage from 'containers/Songs/List';
import CreateSticker from 'containers/Stickers/Form';
import StickersPage from 'containers/Stickers/List';
import Terms_Policy from 'containers/Terms_Policy';
import CreateText from 'containers/Text/Form';
import TextList from 'containers/Text/List';
import CreateTown from 'containers/Towns/Form';
import TownsList from 'containers/Towns/List';
import CreateTransition from 'containers/Transitions/Form';
import TransitionsPage from 'containers/Transitions/List';
import UserDetail from 'containers/Users/Detail';
import CreateUsers from 'containers/Users/Form';
import UsersPage from 'containers/Users/List';
import VideoDetail from 'containers/Videos/Detail';
import VideosPage from 'containers/Videos_New';
import OrderPage from 'containers/Order';
import AirtelManagement from 'containers/Airtel';
import HotelManagement from 'containers/Product/HotelManagement';
import HotelManagementDetail from 'containers/Product/HotelManagement/Detail';
import HotelCuration from 'containers/Product/HotelCuration';
import HotelCurationDetail from 'containers/Product/HotelCuration/HotelCurationDetail';
import ProductRakuten from 'containers/Product/Rakuten';
import ContentsCurationPage from 'containers/ContentsCuration';
import CreatContentCuration from 'containers/ContentsCuration/CreatContentCuration';
import BookmarkList from 'containers/Bookmark/List';
import CreateBookmark from 'containers/Bookmark/Create'
import TutorialList from 'containers/Tutorial/List';
import CreateTutorial from "containers/Tutorial/Create"
import HotSpotRankingList from 'containers/HotSpotRanking/List';
import HotSpotRankingEdit from 'containers/HotSpotRanking/Edit';
import HotelRankingList from 'containers/HotelRanking/List';
import HotelRankingEdit from 'containers/HotelRanking/Edit';
import AirtelProduct from 'containers/AirtelProduct/Management';
import AirtelProductDetail from 'containers/AirtelProduct/Datail';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'utils/routeConstants';

function Routes() {
  return (
    <BrowserRouter>
      <FullLayout>
        <Switch>
          <ProtectedRoute path={ROUTES.REPORTS_LIST} exact component={ReportsPage} />
          <ProtectedRoute path={ROUTES.EDIT_REPORTS} exact component={CreateReports} />
          <ProtectedRoute path={ROUTES.ALBUMS_LIST} exact component={AlbumsPage} />
          <ProtectedRoute path={ROUTES.CREATE_ALBUMS} exact component={CreateAlbums} />
          <ProtectedRoute path={ROUTES.EDIT_ALBUMS} exact component={CreateAlbums} />
          <ProtectedRoute path={ROUTES.ALBUM_DETAIL} exact component={AlbumDetail} />
          <ProtectedRoute
            path={ROUTES.POST_NOTIFICATIONS_LIST}
            exact
            component={PostNotificationsPage}
          />
          <ProtectedRoute
            path={ROUTES.CREATE_POST_NOTIFICATIONS}
            exact
            component={CreatePostNotifications}
          />
          <ProtectedRoute
            path={ROUTES.EDIT_POST_NOTIFICATIONS}
            exact
            component={CreatePostNotifications}
          />
          <ProtectedRoute path={ROUTES.USERS_LIST} exact component={UsersPage} />
          <ProtectedRoute path={ROUTES.USERS_DETAIL} exact component={UserDetail} />
          <ProtectedRoute path={ROUTES.CREATE_USERS} exact component={CreateUsers} />
          <ProtectedRoute path={ROUTES.EDIT_USERS} exact component={CreateUsers} />
          <ProtectedRoute path={ROUTES.VIDEOS_LIST} exact component={VideosPage} />
          <ProtectedRoute path={ROUTES.BOOKMARK_LIST} exact component={BookmarkList} />
          <ProtectedRoute path={ROUTES.BOOKMARK_CREATE} exact component={CreateBookmark} />
          <ProtectedRoute path={ROUTES.TUTORIAL_LIST} exact component={TutorialList} />
          <ProtectedRoute path={ROUTES.TUTORIAL_CREATE} exact component={CreateTutorial} />
          <ProtectedRoute path={ROUTES.HOT_SPOT_RANKING_LIST} exact component={HotSpotRankingList} />
          <ProtectedRoute path={ROUTES.HOT_SPOT_RANKING_EDIT} exact component={HotSpotRankingEdit} />

          <ProtectedRoute path={ROUTES.CONTENTS_CURATION} exact component={ContentsCurationPage} />
          <ProtectedRoute path={ROUTES.CREATE_CONTENTS_CURATION} exact component={CreatContentCuration} />
          <ProtectedRoute path={ROUTES.VIDEO_DETAIL} exact component={VideoDetail} />
          <ProtectedRoute path={ROUTES.ORDER_LIST} exact component={OrderPage} />
          <ProtectedRoute path={ROUTES.ORDER_AIRTEL} exact component={AirtelManagement} />
          <ProtectedRoute path={ROUTES.PRODUCT_HOTEL_MANAGEMENT} exact component={HotelManagement} />
          <ProtectedRoute path={ROUTES.PRODUCT_HOTEL_MANAGEMENT_DETAIL} exact component={HotelManagementDetail} />
          <ProtectedRoute path={ROUTES.PRODUCT_HOTEL_CURATION} exact component={HotelCuration} />
          <ProtectedRoute path={ROUTES.PRODUCT_HOTEL_CURATION_DETAIL} exact component={HotelCurationDetail} />
          <ProtectedRoute path={ROUTES.PRODUCT_HOTEL_RANKING_LIST} exact component={HotelRankingList} />
          <ProtectedRoute path={ROUTES.PRODUCT_HOTEL_RANKING_EDIT} exact component={HotelRankingEdit} />
          <ProtectedRoute path={ROUTES.PRODUCT_RAKUTEN} exact component={ProductRakuten} />
          <ProtectedRoute path={ROUTES.PRODUCT_AIRTEL} exact component={AirtelProduct} />
          <ProtectedRoute path={ROUTES.PRODUCT_AIRTEL_DETAIL} exact component={AirtelProductDetail} />
          <ProtectedRoute path={ROUTES.HOMEPAGE} exact component={HomePage} />
          <ProtectedRoute path={ROUTES.SONGS_LIST} exact component={SongsPage} />
          <ProtectedRoute path={ROUTES.CREATE_SONG} exact component={CreateSong} />
          <ProtectedRoute path={ROUTES.EDIT_SONG} exact component={CreateSong} />
          <ProtectedRoute path={ROUTES.SONG_DETAIL} exact component={SongDetail} />
          <ProtectedRoute path={ROUTES.STICKERS} exact component={StickersPage} />
          <ProtectedRoute path={ROUTES.CREATE_STICKERS} exact component={CreateSticker} />
          <ProtectedRoute path={ROUTES.EDIT_STICKERS} exact component={CreateSticker} />
          <ProtectedRoute path={ROUTES.TRANSITIONS} exact component={TransitionsPage} />
          <ProtectedRoute path={ROUTES.CREATE_TRANSITIONS} exact component={CreateTransition} />
          <ProtectedRoute path={ROUTES.EDIT_TRANSITIONS} exact component={CreateTransition} />
          <ProtectedRoute path={ROUTES.FILTERS} exact component={FiltersPage} />
          <ProtectedRoute path={ROUTES.CREATE_FILTERS} exact component={CreateFilter} />
          <ProtectedRoute path={ROUTES.EDIT_FILTERS} exact component={CreateFilter} />
          <ProtectedRoute path={ROUTES.COUNTRIES} exact component={CountriesList} />
          <ProtectedRoute path={ROUTES.EDIT_COUNTRY} exact component={CreateCountry} />
          <ProtectedRoute path={ROUTES.CITIES} exact component={CitiesList} />
          <ProtectedRoute path={ROUTES.EDIT_CITY} exact component={CreateCity} />
          <ProtectedRoute path={ROUTES.TOWNS} exact component={TownsList} />
          <ProtectedRoute path={ROUTES.EDIT_TOWN} exact component={CreateTown} />
          <ProtectedRoute path={ROUTES.HASHTAGS} exact component={HashtagList} />
          <ProtectedRoute path={ROUTES.EDIT_HASHTAG} exact component={CreateTag} />
          <ProtectedRoute path={ROUTES.CREATE_HASHTAG} exact component={CreateTag} />
          <ProtectedRoute path={ROUTES.TEXTS} exact component={TextList} />
          <ProtectedRoute path={ROUTES.EDIT_TEXT} exact component={CreateText} />
          <ProtectedRoute path={ROUTES.CREATE_TEXT} exact component={CreateText} />
          <ProtectedRoute path={ROUTES.FRAMES} exact component={FrameList} />

          <ProtectedRoute path={ROUTES.CATEGORIES} exact component={CategoriesList} />
          <ProtectedRoute path={ROUTES.CREATE_CATEGORY} exact component={CreateCategory} />
          <ProtectedRoute path={ROUTES.EDIT_CATEGORY} exact component={CreateCategory} />

          <ProtectedRoute path={ROUTES.EVENTS} exact component={EventsList} />
          <ProtectedRoute path={ROUTES.EVENTS_POPUP} exact component={EventsPopup} />
          <ProtectedRoute path={ROUTES.EVENTS_SHOP_BANNER} exact component={EventsBanner} />
          <ProtectedRoute
            path={ROUTES.PUSH_NOTIFICATIONS}
            exact
            component={PushNotificationsList}
          />

          <Route path={ROUTES.PRIVACY} exact component={Privacy} />
          <Route path={ROUTES.TERMS_POLICY} exact component={Terms_Policy} />
          <Route path={ROUTES.LOGIN} exact component={LoginPage} />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </FullLayout>
    </BrowserRouter>
  );
}

export default Routes;
