import { getGeneralFilterTypes, COLLECTION_TYPES } from './constants';
import { DatePicker } from 'components/DatePicker';
import { Input, Select, InputNumber, TimePicker } from 'antd';

import { enUS } from 'date-fns/locale';
import { formatISO, endOfDay, startOfDay } from 'date-fns';

import { DEFAULT_FORMAT_DATE, EXPIRY_DATE_FORMAT } from 'utils/constants';
import { textLabelling } from 'utils/functions';

const { TextArea } = Input;

String.prototype.toMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return minutes + ':' + seconds;
};

const handleGetFiltersInfo = (ALL_ATTRIBUTES, pickDataIndex = true) => {
  let filtersObj = Object.entries(ALL_ATTRIBUTES).map((item) => ({
    label: item[1].label ? item[1].label : item[0],
    attribute: item[1].dataIndex && pickDataIndex ? item[1].dataIndex : item[0],
    type: item[1].type ? item[1].type : 'string',
    values: item[1].type === 'enumeration' ? item[1].enum : [],
    required: item[1].required ? item[1].required : false,
    filterTypes: getGeneralFilterTypes(item[1].type),
    default: item[1].default ? item[1].default : null,
    col: item[1].col ? item[1].col : 24,
    parent: item[1].parent ? item[1].parent : undefined,
  }));
  return filtersObj;
};

const isEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const filterObjectByKeyArr = (objItem, keyArr) => {
  if (isEmpty(objItem) || !keyArr || keyArr.length < 1) return {};
  const filtered = Object.keys(objItem)
    .filter((key) => keyArr.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: objItem[key],
      };
    }, {});
  return filtered;
};

const getAttributesToShow = (objItem, keyName = 'show') => {
  if (isEmpty(objItem)) return {};
  const result = Object.entries(objItem)
    .filter((item) => item[1].via == undefined && item[1][keyName] == true)
    .reduce((obj, value) => {
      obj[value[0]] = value[1];
      return obj;
    }, {});
  return result;
};

const getDataByAttrName = (value, filtersData) => {
  const item = filtersData.find((item) => item.attribute === value);
  if (!item) return filtersData[0];
  return item;
};

const handleRenderInput = (value, filtersData) => {
  const { type, values, attribute } = getDataByAttrName(value, filtersData);

  return handleInputType(type, values, attribute);
};

const handleInputType = (type, values, attribute, options) => {
  let inputType;
  switch (true) {
    case COLLECTION_TYPES.TEXT.includes(type):
      inputType = <Input {...options} />;
      break;
    case COLLECTION_TYPES.TEXTAREA.includes(type):
      inputType = <TextArea rows={4} {...options} />;
      break;
    case COLLECTION_TYPES.NUMBER.includes(type):
      inputType = <InputNumber {...options} />;
      break;
    case COLLECTION_TYPES.DATE.includes(type):
      inputType = (
        <DatePicker
          locale={enUS}
          format={attribute === 'expiryDate' ? EXPIRY_DATE_FORMAT : DEFAULT_FORMAT_DATE}
          showTime={attribute !== 'expiryDate'}
          {...options}
        />
      );
      break;
    case COLLECTION_TYPES.TIME.includes(type):
      inputType = <TimePicker format="h a" {...options} />;
      break;
    case COLLECTION_TYPES.BOOLEAN.includes(type):
      inputType = (
        <Select placeholder="Select a option" allowClear {...options}>
          <Select.Option key={true} value={true}>
            true
          </Select.Option>
          <Select.Option key={false} value={false}>
            false
          </Select.Option>
        </Select>
      );
      break;
    case COLLECTION_TYPES.PASSWORD.includes(type):
      inputType = <Input.Password {...options} />;
      break;
    case COLLECTION_TYPES.ENUMERATION.includes(type):
      inputType = (
        <Select placeholder="Select a option" allowClear {...options}>
          {values?.map((item, index) => (
            <Select.Option key={index} value={item}>
              {attribute === 'resource' ? textLabelling(item) : item}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    case COLLECTION_TYPES.SET.includes(type):
      inputType = (
        <Select
          placeholder="Select a option"
          mode={'multiple'}
          allowClear
          defaultActiveFirstOption={false}
          options={values}
          {...options}
        />
        //   {values.length > 0 &&
        //     values?.map(({ value, label }, index) => (
        //       <Select.Option key={index} value={value}>
        //         {label}
        //       </Select.Option>
        //     ))}
        // </Select>
      );
      break;
    default:
      inputType = <Input />;
      break;
  }
  return inputType;
};

const getDefaultValues = (attributes = {}) => {
  return Object.entries(attributes)
    .filter(([, { default: defaultValue }]) => defaultValue)
    .reduce((prev, curr) => {
      if (curr[1].parent) {
        return {
          ...prev,
          [curr[1].parent]: {
            ...prev[curr[1].parent],
            [curr[0]]: curr[1].default ? curr[1].default : null,
          },
        };
      }
      return {
        ...prev,
        [curr[0]]: curr[1].default ? curr[1].default : null,
      };
    }, {});
};

const generateFileList = (files) =>
  files.map(({ id, name, url }) => ({
    id,
    name,
    url,
  })) || [];

const getDateTimeAttributes = (attributes) =>
  Object.entries(attributes)
    .filter(([, { type }]) => COLLECTION_TYPES.DATE.includes(type))
    .reduce((pre, curr) => {
      let result = pre;
      result.push(curr[0]);

      return result;
    }, []);

const formatDateTimeValues = (
  values,
  attributes,
  isRangeValues = false,
  gte = 'gte',
  lte = 'lte',
) => {
  const dateTimeAttributes = getDateTimeAttributes(attributes);
  return Object.entries(values).reduce((pre, [key, value]) => {
    if (dateTimeAttributes.includes(key)) {
      if (isRangeValues) {
        return {
          ...pre,
          [key]: {
            [gte]: value[gte] ? formatISO(startOfDay(new Date(value[gte]))) : undefined,
            [lte]: value[lte] ? formatISO(endOfDay(new Date(value[lte]))) : undefined,
          },
        };
      }
      return {
        ...pre,
        [key]: value ? formatISO(new Date(value)) : undefined,
      };
    } else {
      return { ...pre, [key]: value };
    }
  }, {});
};

export {
  handleGetFiltersInfo,
  isEmpty,
  filterObjectByKeyArr,
  getAttributesToShow,
  getDataByAttrName,
  handleRenderInput,
  getDefaultValues,
  generateFileList,
  handleInputType,
  getDateTimeAttributes,
  formatDateTimeValues,
};
