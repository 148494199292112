import { push } from 'connected-react-router';
import { THEME, APP_THEMES } from 'utils/constants';
import Storage from 'utils/storage';
import axios from 'axios';
import API from 'utils/apiConstants';
import { handleError } from 'utils/functions';

const app = {
  state: {
    isFirstLoad: false,
    theme: APP_THEMES.LIGHT,
  },
  reducers: {
    setFirstLoad(state, payload) {
      return {
        ...state,
        isFirstLoad: payload,
      };
    },
    setTheme(state, theme) {
      return {
        ...state,
        theme,
      };
    },
  },
  effects: (dispatch) => ({
    async navigateExample() {
      dispatch(push('/login'));
    },
    async getLocation(payload, rootState) {
      return rootState.router.location;
    },
    switchTheme(theme) {
      this.setTheme(theme);
    },
    async upload(file) {
      const formData = new FormData();
      formData.append('files', file);
      try {
        const response = await axios({
          method: 'post',
          url: API.UPLOAD,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwt'))}`,
          },
        });

        return response;
      } catch (error) {
        handleError(error, 'Upload failed');
      }
    },
  }),
};

export default app;
