import { createGlobalStyle } from 'styled-components/macro';
import { colors } from 'components/Styles/Colors';
import { media } from 'components/Styles/Media';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html {
    font-size: 62.5%; //10px;
    height: 100%;
    --antd-wave-shadow-color: ${({ theme }) => colors(theme).primaryColor};
  }
  
  span {
    color: ${({ theme }) => colors(theme).secondaryTextColor};
  }
  
  ::selection {
    color: #fff;
    background: ${({ theme }) => colors(theme).primaryColor};
  }
  
  body {
    font: 1.6rem Calibri, sans-serif;
    color: ${({ theme }) => colors(theme).textColor};
  }
  
  a {
    text-decoration: none;
    color: ${({ theme }) => colors(theme).primaryColor};
  }
  a:hover, a:active {
    color: ${({ theme }) => colors(theme).primaryColor};
  }
  .ant-breadcrumb a {
    color: ${({ theme }) => colors(theme).textColor};
  };
  .ant-breadcrumb > span:last-child a {
    color:  ${({ theme }) => colors(theme).primaryColor};
  }
  .ant-breadcrumb li:last-child a {
    color: ${({ theme }) => colors(theme).textColor};
  };
  
  
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => colors(theme).primaryColor};
    border-color: ${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-menu-dark .ant-menu-item > span > a {
    color: ${({ theme }) => colors(theme).secondaryTextColor};
  }
  .ant-menu-dark .ant-menu-item:hover > span > a {
    color: ${({ theme }) => colors(theme).primaryColor};
  }
  
  .anticon {
    vertical-align: -0.255em;
  }
  .ant-tooltip-inner {
    padding: 0px;
    
  }
  .ant-tooltip-content {
    //box-shadow: 3px 3px 5px black;
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color:none;
      z-index: 1000;
      background-color: white;
    box-shadow: 3px 3px 5px black;
  }

  .ant-menu-item:hover a {
    color: ${({ theme }) => colors(theme).primaryColor};
  }
  .ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-breadcrumb a:hover {
    color: ${({ theme }) => colors(theme).primaryColor};
  }
  .ant-menu-item-selected svg, .ant-menu-submenu-active svg, .ant-menu-submenu-title:hover svg, .ant-menu-item:hover svg {
    fill: ${({ theme }) => colors(theme).primaryColor};
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color:  ${({ theme }) => colors(theme).primaryColor};
  }
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color:${({ theme }) => colors(theme).primaryColor};
  }
    .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: ${({ theme }) => colors(theme).textColor};
    background: ${({ theme }) => colors(theme).backgroundColor};
  }
  
  .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 1px solid ${({ theme }) => colors(theme).borderNormal};
  }
  .ant-menu-dark.ant-menu-horizontal .ant-menu-item:hover {
    background-color: ${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: ${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a, .ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: ${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
    color: ${({ theme }) => colors(theme).primaryColor};
    border-bottom: 2px solid ${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-pagination-item {
    background-color: ${({ theme }) => colors(theme).btnBG};
    a {
      color: ${({ theme }) => colors(theme).textColor};
    }
  }
  
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    background-color: ${({ theme }) => colors(theme).btnBG};
    color: ${({ theme }) => colors(theme).textColor};
  }
  
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color:${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color:${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-pagination-item:hover a, .ant-pagination-item-active a, .ant-pagination-item:hover a, .ant-pagination-item:focus a {
    color:${({ theme }) => colors(theme).primaryColor};
  }
  .ant-pagination-item-active, .ant-pagination-item:hover, .ant-pagination-item:focus {
    border-color:${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color:${({ theme }) => colors(theme).primaryColor};
    border-color:${({ theme }) => colors(theme).primaryColor};
  }
  
  .ant-select-arrow .anticon > svg {
    fill: ${({ theme }) => colors(theme).textColor};
  }
  
  .ant-select-clear, .ant-picker-clear {
    background: ${({ theme }) => colors(theme).inputBG};
    
    .anticon > svg {
      fill: ${({ theme }) => colors(theme).textColor};
    }
    
  }
  
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: ${({ theme }) => colors(theme).inputBG};

      &:hover {
        border-color: ${({ theme }) => colors(theme).primaryColor};
      }
  }
  
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    background-color: ${({ theme }) => colors(theme).inputBG};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({ theme }) => colors(theme).lightPrimaryColor};
    box-shadow: 0 0 0 2px ${({ theme }) => colors(theme).lightShadow250};
  }
  
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f2f2f2;
  }
  
  .ant-select-single {
    &.ant-select-show-arrow .ant-select-selection-search,
    &.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      color: ${({ theme }) => colors(theme).textColor};
    }
  }
  
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
    color: ${({ theme }) => colors(theme).primaryColor};
  }
  .ant-spin-dot-item {
    background-color: ${({ theme }) => colors(theme).primaryColor};
  }
    
  .ant-btn {
    background: ${({ theme }) => colors(theme).btnBG};
    color: ${({ theme }) => colors(theme).btnTextColor};
    
    span {
      color: ${({ theme }) => colors(theme).btnTextColor};
    }
  }
  
  .ant-btn:hover, .ant-btn:focus, .ant-btn:active {
    color: ${({ theme }) => colors(theme).primaryColor};
    border-color:${({ theme }) => colors(theme).primaryColor};
    background: ${({ theme }) => colors(theme).btnBG};
  }
  

  .ant-btn-primary {
    background: ${({ theme }) => colors(theme).primaryColor};
    border-color:${({ theme }) => colors(theme).primaryColor};
    color: ${({ theme }) => colors(theme).btnPrimaryTextColor};
    
    span {
      color: ${({ theme }) => colors(theme).btnPrimaryTextColor};
    }
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary:active {
      background: ${({ theme }) => colors(theme).backgroundColor};
      border-color: ${({ theme }) => colors(theme).primaryColor};
      
    span {
      color: ${({ theme }) => colors(theme).btnPrimaryTextColorHover};
    }
  }
  
  .ant-menu-item-selected {
      color:  ${({ theme }) => colors(theme).primaryColor};
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    &-selected, &-active, & {
      &::after {
        border-bottom: 1px solid transparent;
      }
  
      &:hover, &:active {
        &::after {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
  
  .ant-menu-item a {
    color:  ${({ theme }) => colors(theme).secondaryTextColor};
  }
  
  .ant-layout {
    background-color: ${({ theme }) => colors(theme).contentBgColor};
  }
  
  .ant-layout-footer {
    background-color: ${({ theme }) => colors(theme).contentBgColor};
    color: ${({ theme }) => colors(theme).textColor};
  }
  
  .ant-divider{
    color: ${({ theme }) => colors(theme).textColor};
    border-top: 1px solid ${({ theme }) => colors(theme).borderNormal};
  }
  .ant-divider-vertical {
    border-left: 1px solid ${({ theme }) => colors(theme).borderNormal};
  }
  .ant-divider-horizontal.ant-divider-with-text{
    color: ${({ theme }) => colors(theme).textColor};
    border-top-color: ${({ theme }) => colors(theme).borderNormal};
  }
  
  .ant-form-item-label > label {
    color: ${({ theme }) => colors(theme).textColor};
  }
  
  .ant-input {
    background-color: ${({ theme }) => colors(theme).inputBG};
    color: ${({ theme }) => colors(theme).textColor};

    &:hover, &:active, &:focus {
      border: 1px solid  ${({ theme }) => colors(theme).primaryPink};
    }
    
    &:focus {
      box-shadow: none;
    }
  }

  .ant-input-affix-wrapper {
    background-color: ${({ theme }) => colors(theme).inputBG};
    color: ${({ theme }) => colors(theme).textColor};

    &:hover, &:active, &:focus {
      border: 1px solid  ${({ theme }) => colors(theme).primaryPink};
    }
    
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border: 1px solid  ${({ theme }) => colors(theme).primaryPink};
	  box-shadow: none;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: ${({ theme }) => colors(theme).inputBG};
  }
  
  .ant-input-number {
    background-color: ${({ theme }) => colors(theme).inputBG};
    color: ${({ theme }) => colors(theme).textColor};
    
    &:hover, &:active, &.ant-input-number-focused {
      border: 1px solid  ${({ theme }) => colors(theme).primaryPink};
    }    
    
  
    &:focus, &.ant-input-number-focused {
      box-shadow: none;
    }
  }
  
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
    background: ${({ theme }) => colors(theme).inputBG};
  }
  
  .ant-modal-content {
    background-color: ${({ theme }) => colors(theme).modalBG};
    
    .ant-modal-header {
      background: ${({ theme }) => colors(theme).modalBG};
      border-bottom: 1px solid ${({ theme }) => colors(theme).borderNormal};
    }
    
    .ant-modal-title {
      color: ${({ theme }) => colors(theme).modalTitleText};
    }
    
    .ant-modal-footer {
      border-top: 1px solid ${({ theme }) => colors(theme).borderNormal};
    }
  }
  
  .ant-picker {
    background-color: ${({ theme }) => colors(theme).inputBG};
    .ant-picker-active-bar {
      background: ${({ theme }) => colors(theme).primaryPink};
    }
    .ant-picker-input > input {
      color: ${({ theme }) => colors(theme).textColor};
    }

    &:hover, &:active, &.ant-picker-focused {
      border: 1px solid  ${({ theme }) => colors(theme).primaryPink};
    }    
  
    &:focus, &.ant-picker-focused {
      box-shadow: none;
    }
    
  }
  
  .ant-picker-status-error.ant-picker, .ant-picker-status-error.ant-picker:not([disabled]):hover {
      background-color: ${({ theme }) => colors(theme).inputBG};
  }
  
  .ant-picker-suffix {
    .anticon > svg {
      fill: ${({ theme }) => colors(theme).textColor};
    }
  }
  
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid  ${({ theme }) => colors(theme).primaryPink};
  }
  
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: ${({ theme }) => colors(theme).primaryPink};
  }
  
  .ant-table-thead > tr > th {
    font-weight: bold;
  }
  
  .ant-table {
    color: ${({ theme }) => colors(theme).textColor};
    background: ${({ theme }) => colors(theme).tableCellBG};
  }
  
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${({ theme }) => colors(theme).tableCellBorder};
  }
  
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({ theme }) => colors(theme).tableCellHoverBG};
    color: ${({ theme }) => colors(theme).tableCellHoverText};
  }
  
  .ant-breadcrumb-separator{
    color: ${({ theme }) => colors(theme).textColor};
  }
  
  .ant-tag.ant-tag-has-color > span {
    color: ${({ theme }) => colors(theme).primaryNormal};
  }
  
  .ant-upload.ant-upload-select-picture-card {
    background-color: ${({ theme }) => colors(theme).uploadCardBG};
  
    &:hover {
      border: 1px dashed  ${({ theme }) => colors(theme).primaryPink};
    }
  }
  
  .ant-table-thead > tr > th {
    color: ${({ theme }) => colors(theme).textColor};
    background: ${({ theme }) => colors(theme).secondaryBG};
    border-bottom: 1px solid ${({ theme }) => colors(theme).borderNormal};
  }
  
  .ant-list-split .ant-list-item {
    border-bottom: 1px solid ${({ theme }) => colors(theme).borderNormal};
}

  .ant-list-item-meta-title{
    color: ${({ theme }) => colors(theme).textColor};
  }
  .ant-list-item-meta-description{
    color: ${({ theme }) => colors(theme).secondaryTextColor};
  }

  .ant-upload-list-item-card-actions-btn {
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    color: ${({ theme }) => colors(theme).textColor};
    background: ${({ theme }) => colors(theme).backgroundColor};
  }
  
  .form_cancel-btn {
      margin-left: 12px;
  }
  
  ul {
    list-style: none;
  }
  
  p {
    color: ${({ theme }) => colors(theme).secondaryNormal};
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  
  button {
    cursor: pointer;
  }
  
  input:hover, input:focus, input:active {
    border-color: ${({ theme }) => colors(theme).primaryColor};
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 50px ${({ theme }) => colors(theme).white} inset !important;
  }
  
  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 12px;
  }
  ${media.lg`
    .container {
        max-width: 960px;
        padding: 0 12px;
    }
  `}
  ${media.xl`
    .container {
        max-width: 1140px;
        padding: 0 18px;
    }
  `}
  ${media.xxl`
    .container {
        max-width: 1320px;
        padding: 0 24px;
    }
  `}
  
`;
