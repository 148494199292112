import { APP_THEMES } from 'utils/constants';
import { colors as BasedColors } from 'styles/colors';

export const colors = (theme) => {
  const globalColors = {
    solitude: '#f0f2f5',
    primaryBlue: '#1890ff',
    primaryOrange: '#FD904F',
    brandOrange: '#FD904F',
    darkOrange: '#E37533',
    lightOrange: '#FFF0DA',
    primaryPink: '#F04287',
    darkPink: '#DB086A',
    lightPink: '#FFE4F1',
    customGray: '#FAFAFA',
    lightShadow250: 'rgba(201,201,201,0.25)',
    backgroundDark: '#1C1C1E',
    // Primary Base Colors
    primaryLight: BasedColors.white,
    primaryDark: '#c7c5c6',
    primaryNormal: '#f2f1f1',

    // Secondary Base Colors
    secondaryLight: '#BABFBF',
    secondaryNormal: '#8C8C8C',
    secondaryDark: '#757575',

    // Tertiary Base Colors
    tertiaryLight: '#4A4549',
    tertiaryNormal: '#323032',
    tertiaryDark: '#202020',
    ...BasedColors,
  };

  const lightTheme = {
    // Text
    textColor: globalColors.tertiaryDark,
    secondaryTextColor: globalColors.secondaryDark,
    btnTextColor: globalColors.tertiaryDark,
    btnPrimaryTextColor: globalColors.primaryNormal,
    btnPrimaryTextColorHover: globalColors.primaryPink,

    iconPrimaryColor: globalColors.tertiaryDark,

    //background
    backgroundColor: globalColors.primaryLight,
    secondaryBG: globalColors.primaryNormal,
    tertiaryBG: globalColors.primaryNormal,
    contentBgColor: globalColors.solitude,
    settingThemeBackgroundColor: globalColors.primaryPink,
    primaryColor: globalColors.primaryPink,
    lightPrimaryColor: globalColors.lightPink,
    darkPrimaryColor: globalColors.darkPink,
    brandColor: globalColors.primaryPink,
    lightGradientColor: globalColors.lightPink,
    darkGradientColor: globalColors.darkPink,
    lightSecondaryColor: globalColors.secondaryLight,

    borderNormal: globalColors.primaryNormal,

    btnBG: globalColors.primaryLight,

    //Table
    tableCellBG: globalColors.white,
    secondaryTableCellBG: globalColors.customGray,
    tableCellBorder: globalColors.primaryDark,
    tableCellHoverBG: globalColors.primaryNormal,
    tableCellHoverText: globalColors.tertiaryDark,

    //Modal
    modalBG: globalColors.primaryLight,
    modalText: globalColors.tertiaryDark,
    modalTitleText: globalColors.tertiaryDark,

    //Form
    formLabel: globalColors.tertiaryDark,
    inputBG: globalColors.primaryLight,
    uploadCardBG: globalColors.customGray,

    ...globalColors,
  };

  const darkTheme = {
    // Text
    textColor: globalColors.primaryNormal,
    secondaryTextColor: globalColors.secondaryNormal,
    btnTextColor: globalColors.primaryNormal,
    btnPrimaryTextColor: globalColors.primaryNormal,
    btnPrimaryTextColorHover: globalColors.primaryPink,

    iconPrimaryColor: globalColors.darkPink,

    //background
    backgroundColor: globalColors.backgroundDark,
    secondaryBG: globalColors.tertiaryDark,
    tertiaryBG: globalColors.primaryNormal,
    contentBgColor: globalColors.tertiaryLight,
    settingThemeBackgroundColor: globalColors.primaryPink,
    primaryColor: globalColors.primaryPink,
    lightPrimaryColor: globalColors.lightPink,
    darkPrimaryColor: globalColors.darkPink,
    brandColor: globalColors.primaryPink,
    lightGradientColor: globalColors.lightPink,
    darkGradientColor: globalColors.darkPink,
    lightSecondaryColor: globalColors.secondaryLight,

    borderNormal: globalColors.secondaryDark,

    btnBG: globalColors.tertiaryDark,

    //Table
    tableCellBG: globalColors.tertiaryLight,
    secondaryTableCellBG: globalColors.tertiaryDark,
    tableCellBorder: globalColors.secondaryDark,
    tableCellHoverBG: globalColors.tertiaryDark,
    tableCellHoverText: globalColors.primaryNormal,

    //Modal
    modalBG: globalColors.backgroundDark,
    modalText: globalColors.primaryNormal,
    modalTitleText: globalColors.primaryNormal,

    //Form
    formLabel: globalColors.primaryNormal,
    inputBG: globalColors.tertiaryDark,
    uploadCardBG: globalColors.tertiaryNormal,

    ...globalColors,
  };

  switch (theme) {
    case APP_THEMES.LIGHT:
      return lightTheme;
    case APP_THEMES.DARK:
      return darkTheme;
    default:
      return lightTheme;
  }
};
