import axios from 'axios';
import { push } from 'connected-react-router';
import { URL_SERVER_HOST } from 'utils/constants';

const isAuthenticated = localStorage.getItem('jwt') ? true : false;

export const axiosConfigHeader = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwt'))}`,
  },
};

const authentication = {
  state: {
    isAuthenticated,
    message: '',
  },
  reducers: {
    setIsAuthenticated(state, payload) {
      return {
        ...state,
        isAuthenticated: payload,
      };
    },
    setMessage(state, payload) {
      return {
        ...state,
        message: payload,
      };
    }
  },
  effects: (dispatch) => ({
    async login(payload) {
      const SUPER_ADMIN = {
        identifier: payload.username,
        password: payload.password,
      };
      try {
        const response = await axios.post(`${URL_SERVER_HOST}/auth/local`, SUPER_ADMIN);
        localStorage.setItem('jwt', JSON.stringify(response.data.jwt));
        localStorage.setItem('email', response.data.user.email);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('role', response.data.user.role.type);
        this.setIsAuthenticated(true);
      } catch (error) {
        this.setIsAuthenticated(false);
      }
    },
    async checkLogin(authToken) {
      const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${JSON.parse(authToken)}`,
      };

      try {
        if (localStorage.getItem('jwt')) {
          await axios.get(`${URL_SERVER_HOST}/me`, {
            headers,
          });
          this.setIsAuthenticated(true);
        } else {
          this.setIsAuthenticated(false);
        }
      } catch (error) {
        console.log(error);
        this.logout();
      }
    },
    async logout() {
      this.setIsAuthenticated(false);
      localStorage.removeItem('jwt');
      dispatch(push('/login'));
    },
  }),
};

export default authentication;
