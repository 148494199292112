import React from 'react';
import { useSelector } from 'hooks';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Logo } from '../Logo';
import { media } from '../Styles/Media';
import { colors } from 'components/Styles/Colors';
import { BRAND_TEXT } from 'components/Styles/Logo';

const StyledBrand = styled.div`
  position: relative;
  padding: 16px;
  cursor: pointer;
  background-color: ${({ theme }) => colors(theme).backgroundColor};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => colors(theme).borderNormal};

  h1 {
    display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
    height: 32px;
    max-width: 100%;

    margin: 0 0 0 12px;
    color: ${({ color }) => color};
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 32px;
    vertical-align: middle;
  }
  ${media.lg`
    h1 {
      display: inline-block;
    }
  `}
`;

const Brand = ({ color, collapsed }) => {
  const { theme } = useSelector(({ app: { theme } }) => ({ theme }));

  return (
    <StyledBrand theme={theme} className="logo" color={color} collapsed={collapsed}>
      <Logo></Logo>
    </StyledBrand>
  );
};

Brand.propTypes = {
  brandName: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

Brand.defaultProps = {
  brandName: 'Video Monster',
  bgColor: colors.bgSidebar,
  color: '#fff',
};

export default Brand;
