import { URL_SERVER_HOST, URL_VIIV_SHOP } from './constants';

const generateAPI = (api) => `${URL_SERVER_HOST}/${api}`;
const viivshopAPI = (api) => `${URL_VIIV_SHOP}/${api}`;

export default {
  ADMIN_SPOTS: generateAPI('admins/spots'),
  LOGIN: generateAPI('auth/local'),
  USER: generateAPI('admins/users'),
  VIDEO: generateAPI('admins/videos'),
  REGIONS: generateAPI('regions'),
  VIDEO_FILTER: generateAPI('admins/videos-filter'),
  VIDEO_FILTER_V2: generateAPI('admins/videos-filter-v2'),
  VIDEO_ANALYSIS: generateAPI('admins/analysis/videos'),
  VIDEO_CHART: generateAPI('admins/chart/video_uploaded'),
  VIDEO_TAGS: generateAPI('admins/video-tags'),
  USER_CHART: generateAPI('admins/chart/user_registered'),
  DASHBOARD: generateAPI('admins/total'),
  POST_NOTIFICATIONS: generateAPI('admins/notifications'),
  ALBUMS: generateAPI('admins/albums'),
  REPORTS: generateAPI('admins/reports'),
  APP_USERS: generateAPI('users'),
  REPORT_EXCEL: generateAPI('reportExcel'),
  SONG: generateAPI('admins/songs'),
  GENRE: generateAPI('admins/song-genres'),
  STICKERS: generateAPI('admins/stickers'),
  STICKER_GROUPS: generateAPI('admins/sticker-groups'),
  TRANSITIONS: generateAPI('admins/transitions'),
  FILTERS: generateAPI('admins/effect-filters'),
  UPLOAD: generateAPI('upload'),
  COUNTRY: generateAPI('admins/countries'),
  CITY: generateAPI('admins/cities'),
  TOWN: generateAPI('admins/towns'),
  HASHTAG: generateAPI('admins/hashtags'),
  TEXT: generateAPI('admins/texts'),
  FRAMES: generateAPI('admins/frames'),
  CATEGORIES: generateAPI('admins/categories'),
  EVENTS: generateAPI('admins/events'),
  EVENTS_POPUP: generateAPI('admins/events-popup'),
  EVENT: generateAPI('admins/event'),
  EVENT_V1: viivshopAPI('v1/admin/events'),
  PUSH_NOTIFICATIONS: generateAPI('admins/notifications/conditions'),
  CONTENTS_CURATION: generateAPI('admins/event/feed/playlists'),
  CONTENTS_CURATION_VIDEO: generateAPI('admins/event/feed/playlist'),
  RECOMMEND_BOOKMARKS: generateAPI('admins/event/recommend-bookmarks'),
  RECOMMEND_BOOKMARK: generateAPI('admins/event/recommend-bookmark'),
  VIDEO_RANKING: generateAPI('admins/video-ranking-v2'),
  PLACE_DETAIL: generateAPI('google-place'),
  ADMIN_ORDER_HOTELS: viivshopAPI('v1/admin/order/hotels'),
  ADMIN_ORDER_AIRTELS: viivshopAPI('v1/admin/order/airtels'),
  VOUCHER: viivshopAPI('v1/order/hotels/voucher'),
  TALK_NOTIFICATION: generateAPI('admins/notification/kakaotalk'),
  EMAIL_NOTIFICATION: generateAPI('admins/notification/email'),
  GOOGLE_PLACE: generateAPI('admins/google-place'),
  SHOP_ORDER: viivshopAPI('v1/admin/order/hotels/kr/hotelnjoy'),
  TALK_NOTIFICATION: generateAPI('admins/notification/kakaotalk'),
  SHOP_PRODUCTS: viivshopAPI('v1/admin/products'),
  AREAS: viivshopAPI('v1/admin/areas'),
  HOTEL_TYPES: viivshopAPI('v1/admin/products/hotels/types'),
  HOTELS: viivshopAPI('v1/admin/products/hotels'),
  PRODUCTS_AIRTELS: viivshopAPI('v1/admin/products/airtels'),
  PRODUCT_AIRTELS: viivshopAPI('v1/admin/product/airtels'),
  HOTELS_FEED: viivshopAPI('v1/admin/feed'),
  HOTELS_CURATION: viivshopAPI('v1/admin/feed/curations'),
};
