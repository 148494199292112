/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Layout as AtndLayout } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UserOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';

import { Header } from 'components/Header';
import { Brand } from 'components/Brand';
import { Sidebar } from 'components/Sidebar';
import { colors } from 'components/Styles/Colors';
import { Footer } from 'components/Footer';
import { mediumSize, media } from 'components/Styles/Media';

import { menuList } from 'utils/menu';
import { PUBLIC_ROUTES, ROUTES } from 'utils/routeConstants';

const { Content } = AtndLayout;

const StyledLayout = styled(AtndLayout)`
  min-height: 100vh;
  background-color: ${colors.contentBgColor};

  .ant-breadcrumb {
    margin: 16px 24px;
  }

  ${media.md`
    max-height: 100vh;

    .site-layout {
      .main-wrapper {
        overflow: auto;
      }
    }
  `}
`;

const FullLayout = ({ children, className, menuOpenedKey, menuSelectedKey }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(!!mediumSize());

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const items = [
    // {
    //   title: t('dropdown.my_profile', 'My Profile'),
    //   icon: <UserOutlined />,
    //   path: '/',
    //   key: 'my_profile',
    // },
    // {
    //   title: t('dropdown.setting', 'Setting'),
    //   icon: <SettingOutlined />,
    //   path: '/',
    //   hasDivider: true,
    //   key: 'setting',
    // },
    {
      title: t('dropdown.logout', 'Logout'),
      icon: <LogoutOutlined />,
      path: '/',
      key: 'logout',
    },
  ];

  const isPubicRoutes =
    PUBLIC_ROUTES.includes(pathname) || !Object.values(ROUTES).includes(pathname);

  const userName = localStorage.getItem('username');
  return (
    <>
      {isPubicRoutes ? (
        <>{children}</>
      ) : (
        <StyledLayout className={className}>
          <Sidebar
            items={menuList()}
            collapsed={collapsed}
            toggleSidebar={toggleSidebar}
            openedKey={menuOpenedKey}
            selectedKey={menuSelectedKey}
          >
            <Brand collapsed={collapsed} />
          </Sidebar>
          <AtndLayout className="site-layout">
            <Header toggleSidebar={toggleSidebar} items={items} userName={userName} />
            <div className="main-wrapper" id="mainWrap">
              <Content>{children}</Content>
              <Footer className="footer" />
            </div>
          </AtndLayout>
        </StyledLayout>
      )}
    </>
  );
};

export default FullLayout;
