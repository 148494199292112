import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'hooks';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const { setIsAuthenticated, checkLogin } = useDispatch(({ authentication }) => ({
    setIsAuthenticated: authentication.setIsAuthenticated,
    checkLogin: authentication.checkLogin,
  }));

  const jwt = localStorage.getItem('jwt');

  useEffect(() => {
    if (jwt) {
      checkLogin(jwt);
    } else {
      setIsAuthenticated(false);
    }
  }, [checkLogin, jwt, setIsAuthenticated]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? <Component {...rest} {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export default ProtectedRoute;
