import React from 'react';

import { GlobalStyles } from 'components/Styles';
import { HelmetProvider } from 'react-helmet-async';
import Routes from './Routes';
import './App.css';
import { useSelector } from 'react-redux';

function App() {
  const theme = useSelector((state) => state.app.theme);

  return (
    <HelmetProvider>
      <Routes />
      <GlobalStyles theme={theme} />
    </HelmetProvider>
  );
}

export default App;
