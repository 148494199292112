export const colors = {
  white: '#ffffff',
  black: '#000000',
  gray: '#8C8C8C',
  lightGray: '#BABFBF',
  darkGray: '#757575',
  green: '#3ac47d',
  warning: '#ffc107',
  danger: '#d92550',
  whiteNormal: '#F1F5F5',
  blackText: 'rgba(0,0,0,.85)',
};
