import React from 'react';
import { useSelector } from 'hooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { media } from 'components/Styles/Media';
import { colors } from 'components/Styles/Colors';

const StyledFooter = styled(Layout.Footer)`
  text-align: ${(props) => props.align};
  font-size: 1.4rem;

  ${media.xxs`
    a {
      float: right;
    }
  `}

  .company-name {
    color: ${({ theme }) => colors(theme).brandColor};
  }
`;

const Footer = ({ companyName, align, children, year, ...rest }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const { theme } = useSelector(({ app: { theme } }) => ({ theme }));

  return (
    <StyledFooter theme={theme} {...rest} align={align}>
      ©{year || currentYear} {t('footer.created_by', 'Created by')}{' '}
      <strong className={'company-name'}>{companyName}</strong>
      {children}
    </StyledFooter>
  );
};

Footer.propTypes = {
  align: PropTypes.string,
  companyName: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
  align: 'center',
  companyName: 'ViiV',
};

export default Footer;
