import React from 'react';
import { useDispatch, useSelector } from 'hooks';
import { Switch } from 'antd';
import styled from 'styled-components';
import { colors } from 'components/Styles/Colors';

import { APP_THEMES } from 'utils/constants';

const SwitchWrapper = styled.div`
  align-self: center;
  margin-left: 16px;

  .ant-switch {
    border: 1px solid ${({ theme }) => colors(theme).primaryColor};
  }

  .ant-switch-handle {
    top: 1px;
  }
  .ant-switch-handle::before {
    background-color: ${({ theme }) => colors(theme).darkPrimaryColor};
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => colors(theme).primaryLight};
    .ant-switch-inner {
      color: ${({ theme }) => colors(theme).tertiaryDark};
    }
  }
`;

const ThemeSwitcher = () => {
  const { switchTheme } = useDispatch(({ app: { switchTheme } }) => ({ switchTheme }));
  const { theme } = useSelector(({ app: { theme } }) => ({ theme }));

  const onChange = (checked) => {
    switch (checked) {
      case true:
        switchTheme(APP_THEMES.LIGHT);
        break;
      case false:
        switchTheme(APP_THEMES.DARK);
        break;
      default:
        return;
    }
  };

  return (
    <SwitchWrapper theme={theme}>
      <Switch
        checkedChildren={APP_THEMES.LIGHT}
        unCheckedChildren={APP_THEMES.DARK}
        defaultChecked={theme === APP_THEMES.LIGHT}
        onChange={onChange}
      />
    </SwitchWrapper>
  );
};

export default ThemeSwitcher;
