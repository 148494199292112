import {
  HomeOutlined,
  UserOutlined,
  YoutubeOutlined,
  CalculatorOutlined,
  AlertOutlined,
  // NotificationOutlined,
  GiftOutlined,
  PictureOutlined,
  PlayCircleOutlined,
  BgColorsOutlined,
  RiseOutlined,
  FileOutlined,
  GlobalOutlined,
  CarOutlined,
  ProjectOutlined,
  BorderlessTableOutlined,
  FontColorsOutlined,
  BorderOuterOutlined,
  UnorderedListOutlined,
  AuditOutlined,
  AlertFilled,
} from '@ant-design/icons';
import { ROUTES } from './routeConstants';
import i18n from 'i18n';

export const menuList = () => [
  {
    key: '/',
    title: 'Home',
    icon: <HomeOutlined />,
    path: ROUTES.HOMEPAGE,
  },
  {
    key: 'reports',
    title: 'Reports',
    icon: <AlertOutlined />,
    subMenu: [{ key: ROUTES.REPORTS_LIST, title: 'Reports List', path: ROUTES.REPORTS_LIST }],
  },
  {
    key: 'events',
    title: 'Events',
    icon: <AuditOutlined />,
    subMenu: [
      {
        key: ROUTES.EVENTS,
        title: `${i18n.t('menu.frames.events', 'Events')}`,
        path: ROUTES.EVENTS,
      },
	  {
        key: ROUTES.EVENTS_POPUP,
        title: `${i18n.t('menu.frames.events_popup', 'Events Popup')}`,
        path: ROUTES.EVENTS_POPUP,
      },
	  {
        key: ROUTES.EVENTS_SHOP_BANNER,
        title: `Shop Banners`,
        path: ROUTES.EVENTS_SHOP_BANNER,
      },
    ],
  },
  {
    key: 'push-notifications',
    title: 'Admin Notification',
    icon: <AlertFilled />,
    subMenu: [
      {
        key: ROUTES.PUSH_NOTIFICATIONS,
        title: `${i18n.t('menu.frames.push-notifications', 'Admin Notification List')}`,
        path: ROUTES.PUSH_NOTIFICATIONS,
      },
    ],
  },
  {
    key: 'users',
    title: 'Users',
    icon: <UserOutlined />,
    subMenu: [
      {
        key: ROUTES.USERS_LIST,
        title: `${i18n.t('menu.users.list', 'User List')}`,
        path: ROUTES.USERS_LIST,
      },
    ],
  },
  {
    key: 'videos',
    title: 'Videos',
    icon: <YoutubeOutlined />,
    subMenu: [
      {
        key: ROUTES.VIDEOS_LIST,
        title: `${i18n.t('menu.videos.list', 'Videos List')}`,
        path: ROUTES.VIDEOS_LIST,
      },
      {
        key: ROUTES.CONTENTS_CURATION,
        title: `${i18n.t('menu.videos.contentscuration', 'Contents Curation')}`,
        path: ROUTES.CONTENTS_CURATION,
      },
      {
        key: ROUTES.BOOKMARK_LIST,
        title: `${i18n.t('menu.videos.bookmark.list', 'Bookmark List')}`,
        path: ROUTES.BOOKMARK_LIST,
      },
      {
        key: ROUTES.TUTORIAL_LIST,
        title: `${i18n.t('menu.videos.tutorial.list', 'Tutorial List')}`,
        path: ROUTES.TUTORIAL_LIST,
      },
      {
        key: ROUTES.HOT_SPOT_RANKING_LIST,
        title: `${i18n.t('menu.videos.spot.ranking', 'Hot spot Ranking')}`,
        path: ROUTES.HOT_SPOT_RANKING_LIST,
      },
    ],
  },
  {
    key: 'order',
    title: 'Order',
    icon: <CalculatorOutlined />,
    subMenu: [
      {
        key: ROUTES.ORDER_LIST,
        title: `${i18n.t('menu.order.management', 'Order Management')}`,
        path: ROUTES.ORDER_LIST,
      },
	  {
        key: ROUTES.ORDER_AIRTEL,
        title: `${i18n.t('menu.order.airtel', 'Airtel Management')}`,
        path: ROUTES.ORDER_AIRTEL,
      },
    ],
  },
  {
    key: 'product',
    title: 'Products',
    icon: <GiftOutlined />,
    subMenu: [
      {
        key: ROUTES.PRODUCT_HOTEL_MANAGEMENT,
        title: `${i18n.t('menu.product.hotelmanagement', 'Hotel Management')}`,
        path: ROUTES.PRODUCT_HOTEL_MANAGEMENT,
      },
	  {
        key: ROUTES.PRODUCT_AIRTEL,
        title: `${i18n.t('menu.product.airtel', 'Airtel Management')}`,
        path: ROUTES.PRODUCT_AIRTEL,
      },
	  {
        key: ROUTES.PRODUCT_HOTEL_CURATION,
        title: `Hotel Curation`,
        path: ROUTES.PRODUCT_HOTEL_CURATION,
      },
	  {
        key: ROUTES.PRODUCT_HOTEL_RANKING_LIST,
        title: `Hotel Ranking`,
        path: ROUTES.PRODUCT_HOTEL_RANKING_LIST,
      },
      {
        key: ROUTES.PRODUCT_RAKUTEN,
        title: `${i18n.t('menu.product.rakuten', 'Rakuten')}`,
        path: ROUTES.PRODUCT_RAKUTEN,
      },
    ],
  },
  // {
  //   key: 'categories',
  //   title: 'Categories',
  //   icon: <UnorderedListOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.CATEGORIES,
  //       title: `${i18n.t('menu.categories.list', 'Categories List')}`,
  //       path: ROUTES.CATEGORIES,
  //     },
  //     {
  //       key: ROUTES.CREATE_CATEGORY,
  //       title: `${i18n.t('menu.categories.create', 'Create Category')}`,
  //       path: ROUTES.CREATE_CATEGORY,
  //     },
  //   ],
  // },
  // {
  //   key: 'countries',
  //   title: 'Countries',
  //   icon: <GlobalOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.COUNTRIES,
  //       title: 'Countries',
  //       path: ROUTES.COUNTRIES,
  //     },
  //   ],
  // },
  // {
  //   key: 'cities',
  //   title: 'Cities',
  //   icon: <CarOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.CITIES,
  //       title: 'Cities',
  //       path: ROUTES.CITIES,
  //     },
  //   ],
  // },
  // {
  //   key: 'towns',
  //   title: 'Towns',
  //   icon: <PictureOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.TOWNS,
  //       title: 'Towns',
  //       path: ROUTES.TOWNS,
  //     },
  //   ],
  // },
  // {
  //   key: 'hashtags',
  //   title: 'Hashtags',
  //   icon: <BorderlessTableOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.HASHTAGS,
  //       title: 'Hashtags',
  //       path: ROUTES.HASHTAGS,
  //     },
  //     {
  //       key: ROUTES.CREATE_HASHTAG,
  //       title: 'Create Hashtags',
  //       path: ROUTES.CREATE_HASHTAG,
  //     },
  //   ],
  // },
  // {
  //   key: 'texts',
  //   title: 'Texts',
  //   icon: <FontColorsOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.TEXTS,
  //       title: 'Texts',
  //       path: ROUTES.TEXTS,
  //     },
  //     {
  //       key: ROUTES.CREATE_TEXT,
  //       title: 'Create Text',
  //       path: ROUTES.CREATE_TEXT,
  //     },
  //   ],
  // },
  // {
  //   key: 'songs',
  //   title: 'Songs',
  //   icon: <PlayCircleOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.SONGS_LIST,
  //       title: `${i18n.t('menu.songs.list', 'Songs List')}`,
  //       path: ROUTES.SONGS_LIST,
  //     },
  //     {
  //       key: ROUTES.CREATE_SONG,
  //       title: `${i18n.t('menu.songs.create', 'Create Song')}`,
  //       path: ROUTES.CREATE_SONG,
  //     },
  //   ],
  // },
  // {
  //   key: 'projects',
  //   title: 'Projects',
  //   icon: <ProjectOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.ALBUMS_LIST,
  //       title: `${i18n.t('menu.albums.list', 'Projects List')}`,
  //       path: ROUTES.ALBUMS_LIST,
  //     },
  //   ],
  // },
  /*{
    key: 'post',
    title: 'Post Notifications',
    icon: <NotificationOutlined />,
    subMenu: [
      {
        key: ROUTES.POST_NOTIFICATIONS_LIST,
        title: `${i18n.t('menu.notifications.list', 'Notifications List')}`,
        path: ROUTES.POST_NOTIFICATIONS_LIST,
      },
      {
        key: ROUTES.CREATE_POST_NOTIFICATIONS,
        title: `${i18n.t('menu.notifications.create', 'Push Events')}`,
        path: ROUTES.CREATE_POST_NOTIFICATIONS,
      },
    ],
  },*/
  // {
  //   key: 'stickers',
  //   title: 'Stickers',
  //   icon: <FileOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.STICKERS,
  //       title: `${i18n.t('menu.stickers.list', 'Stickers')}`,
  //       path: ROUTES.STICKERS,
  //     },
  //     {
  //       key: ROUTES.CREATE_STICKERS,
  //       title: `${i18n.t('menu.stickers.create', 'Add Sticker')}`,
  //       path: ROUTES.CREATE_STICKERS,
  //     },
  //   ],
  // },
  // {
  //   key: 'transitions',
  //   title: 'Transitions',
  //   icon: <RiseOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.TRANSITIONS,
  //       title: `${i18n.t('menu.transitions.list', 'Transitions')}`,
  //       path: ROUTES.TRANSITIONS,
  //     },
  //     {
  //       key: ROUTES.CREATE_TRANSITIONS,
  //       title: `${i18n.t('menu.transitions.create', 'Add Transition')}`,
  //       path: ROUTES.CREATE_TRANSITIONS,
  //     },
  //   ],
  // },
  // {
  //   key: 'filters',
  //   title: 'Filters',
  //   icon: <BgColorsOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.FILTERS,
  //       title: `${i18n.t('menu.filters.list', 'Filters')}`,
  //       path: ROUTES.FILTERS,
  //     },
  //     {
  //       key: ROUTES.CREATE_FILTERS,
  //       title: `${i18n.t('menu.filters.create', 'Add Filter')}`,
  //       path: ROUTES.CREATE_FILTERS,
  //     },
  //   ],
  // },
  // {
  //   key: 'frames',
  //   title: 'Frames',
  //   icon: <BorderOuterOutlined />,
  //   subMenu: [
  //     {
  //       key: ROUTES.FRAMES,
  //       title: `${i18n.t('menu.frames.list', 'Frames')}`,
  //       path: ROUTES.FRAMES,
  //     },
  //   ],
  // },
];
